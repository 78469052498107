






























































































































































































































































































































































































@import '~@/assets/styles/components/tableWithVDeep';
.v-data-table thead tr th,
.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: $colorPrimary !important;
}

.box-table {
  // border-top: 5px solid $colorPrimary;
  // border-top-left-radius: 0 !important;
  // border-top-right-radius: 0 !important;

  .v-data-table-header-mobile {
    display: none;
  }

  &.box-table-gecom {
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
      height: auto;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

.fab-cadastrar-usuario {
  position: fixed;
  z-index: 1;
  bottom: 25px;
  right: 5px;

  @media (min-width: 599px) {
    display: none !important;
  }
}

.range-date::v-deep {
  .v-btn {
    height: 38px !important;
    width: 100%;
  }
}

.select-status::v-deep {
  .v-input__slot {
    margin-bottom: 0 !important;
  }
}

.bilhete-cancelado {
  background-color: $colorError;
  color: #FFF !important;
}

.bilhete-premiado {
  background-color: $colorSecondary;
  color: #FFF !important;
}
